<template>
  <v-col lg="12">
    <div class="tab-default">
      <v-tabs v-model="tab" flat hide-slider>
        <v-tab v-for="item in items" :key="item.id" :ripple="false">
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          transition="fade-transition"
          v-for="item in tabContent"
          :key="item.id"
        >
          <v-card flat>
            <v-card-text>
              <div class="single-tab-content">
                <ul>
                  <li v-for="(item, i) in item.content" :key="i">
                    <router-link to="#"> <!--/service-->
                      {{ item.title }}
                      <span> - {{ item.name }}</span>
                    </router-link>
                    {{ item.desc }}
                  </li>
                </ul>
              </div>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-col>
</template>
<script>
  export default {
    data() {
      return {
        tab: null,
        items: [
          {
            id: 1,
            name: "Tech Stack",
          },
          {
            id: 2,
            name: "Education",
          },
          {
            id: 3,
            name: "Experience",
          },
          {
            id: 4,
            name: "Certification",
          },
        ],

        tabContent: [
          {
            id: 1,
            content: [
              {
                title: "Back-End Development",
                name: "Design & Development",
                desc: "Java (Spring Boot), PHP (Laravel), Python, NodeJs",
              },
              {
                title: "Application Consultation",
                name: "API Integration, IFS APPS 10, Aurena, Cloud",
                desc: "Customizations/Configurations consultation, Data Migration",
              },
              {
                title: "Front-End Development",
                name: "Development",
                desc: "Vue, Angular, React, JavaScript, TypeScript, HTML, CSS",
              },
              {
                title: "Database",
                name: "Design & Development",
                desc: "MySQL, Oracle PL/SQL, Postgres",
              },
              {
                title: "CI/CD & Test Automation",
                name: "Development",
                desc: "GIT, Docker, K8s, Selenium, JUnit",
              },
            ],
          },
          {
            id: 2,
            content: [
              {
                title: "MSc in Computer Science and Engineering",
                name: "University of Oulu, Finland",
                desc: " 2021 - 2023",
              },
              {
                title: "BSc in Computer Science",
                name: "University College Dublin, Ireland",
                desc: "2015 - 2019",
              },
            ],
          },
          {
            id: 3,
            content: [
              {
                title: "Software Engineer (IFS Consultant)",
                name: "BakerTilly LLP.",
                desc: "2021/07 - 2023/06",
              },
              {
                title: "Software Engineer - Full-stack",
                name: "OpusXenta Lanka",
                desc: "2020/02 - 2021/06",
              },
              {
                title: "Junior Software Engineer - Full-stack",
                name: "OpusXenta Lanka",
                desc: "2019/08 - 2020/02",
              },
              {
                title: "Associate Software Engineer - Full-stack",
                name: "ExamGenius",
                desc: "2018/06 - 2019/07",
              },
            ],
          },
          {
            id: 4,
            content: [
              {
                title: "IFS Certified Practitioner -Technical Consultant (IFSApplications 10)",
                name: "IFS Academy (2022)",
                desc: "https://www.credly.com/badges/72132302-6cab-4c11-b255-6528ce8cd9f2?source=linked_in_profile",
              },
              {
                title: "IFS Practitioner - TechnicalConsultant (IFS Cloud)",
                name: "IFS Academy - (2023)",
                desc: "https://www.credly.com/badges/72132302-6cab-4c11-b255-6528ce8cd9f2?source=linked_in_profile",
              },
            ],
          },
        ],
      };
    },
  };
</script>
