<template>
  <v-row align="start" class="row--35">
    <v-col lg="6" md="6" sm="12" cols="12" order="1" order-md="2">
      <div class="thumbnail mb_md--40 mb_sm--40">
        <img src="../../assets/images/about/about-66.jpg" alt="contact" />
      </div>
    </v-col>
    <v-col lg="6" md="6" sm="12" cols="12" order="2" order-md="1">
      <div class="section-title text-left mb--50 mb_sm--30 mb_md--30">
        <h2 class="heading-title">Get in touch.</h2>
        <p class="description">
          Feel free to reach out to me. I will be delighted to get in touch with you.
        </p>
      </div>
      <div class="form-wrapper">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider
              name="name"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input

                  type="text"
                  v-model="formData.name"
                  placeholder="Your Name *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <label>
                <input

                  type="email"
                  rules="required|email"
                  v-model="formData.email"
                  placeholder="Your email *"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="subject"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <input

                  type="text"
                  v-model="formData.subject"
                  placeholder="Write a Subject"
                />
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <label>
                <textarea

                  v-model="formData.message"
                  placeholder="Your Message"
                ></textarea>
                <span class="inpur-error">{{ errors[0] }}</span>
              </label>
            </ValidationProvider>

            <button
              class="rn-button-style--2 btn_solid"
              type="submit"
              value="submit"
            >
              Submit
            </button>
<!--            <button-->
<!--              class="rn-button-style&#45;&#45;2 btn_solid"-->
<!--              @click.prevent="showMessage"-->
<!--            >-->
<!--              Connect-->
<!--            </button>-->
          </form>
        </ValidationObserver>
      </div>
    </v-col>

  </v-row>
</template>

<script>
  import { ValidationObserver } from "vee-validate";
  import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
  import axios from 'axios';
  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
    },
    data() {
      return {
        formData: {
          name: "",
          email: "",
          subject: "",
          message: "",
        },
      };
    },
    methods: {
      onSubmit() {
        console.log(this.formData);
        axios.post('/api/send', {
          "Messages": [
            {
              "From": {
                "Email": "ashan.dhk@gmail.com",
                "Name": this.formData.name
              },
              "To": [
                {
                  "Email": "ashan.dhk@gmail.com",
                  "Name": "Ashan Chameera"
                }
              ],
              "TemplateID": 4936702,
              "TemplateLanguage": true,
              "Subject": "New Message",
              "Variables": {
                "cus_name": this.formData.name,
                "email": this.formData.email,
                "subject": this.formData.subject,
                "message": this.formData.message
              }
            }
          ]
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa("b8df20c87462a8f7a4816d13deaf4034:3ff3bb134e589f81bb67538981ea6064")}`
          }
        }).then(response => {
          this.sendCustomerCopy();
          this.$swal('Thank you!', "I'm grateful to hear from you. Please check your mailbox. It can reside in Junk / Spam Folders.", 'success')
          this.formData.name = ''
          this.formData.email = ''
          this.formData.subject = ''
          this.formData.message = ''
        }).catch(error => {
          this.$swal('Oops!', 'Something went wrong with our mail service. Kindly reach me via social media (LinkedIn / Facebook)', 'error')
        });
      },
      sendCustomerCopy() {
        axios.post('/api/send', {
          "Messages": [
            {
              "From": {
                "Email": "ashan.dhk@gmail.com",
                "Name": "Ashan C. Walpita"
              },
              "To": [
                {
                  "Email": this.formData.email,
                  "Name": this.formData.name
                }
              ],
              "TemplateID": 4936463,
              "TemplateLanguage": true,
              "Subject": "Thank you! - Ashan C. Walpita",
              "Variables": {
                "cus_name": this.formData.name
              }
            }
          ]
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${btoa("b8df20c87462a8f7a4816d13deaf4034:3ff3bb134e589f81bb67538981ea6064")}`
          }
        })
            .then(response => {
              // Handle successful response
            })
            .catch(error => {
              // Handle error
            });

      },
      showMessage() {
        this.$swal('Thank you!', "I'm grateful to hear from you. My Email service is under constructions. Please connect me via LinkedIn", 'success')
      }
    },
  };
</script>
