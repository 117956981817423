<template>
  <div class="row mt_dec--30">
    <!-- Start Single Service  -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service service__style--2 bg-color-gray">
        <router-link to="#"> <!--/service-details-->
          <div class="service">
            <div class="icon">
              <div v-html="iconSvg(service.icon)"></div>
            </div>
            <div class="content">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
  import feather from "feather-icons";
  export default {
    data() {
      return {
        serviceContent: [
          {
            icon: "layers",
            title: "Full-Stack Development",
            desc: `Proficiently navigate both the frontend and backend realms, seamlessly integrating intuitive user interfaces with powerful server-side functionality, to create dynamic and comprehensive web applications.`,
          },
          {
            icon: "monitor",
            title: "API Integration",
            desc: `Seamlessly connect and leverage of various systems, platforms, and services, facilitating seamless data exchange and enhancing the functionality and efficiency of applications.`,
          },
          {
            icon: "cast",
            title: "Consultation",
            desc: ` With a customer-centric approach, my consultation skills empower businesses to optimize their operations and streamline workflows, leveraging my expertise to deliver tailored solutions that meet their specific needs and drive long-term success.`,
          },
          // {
          //   icon: "monitor",
          //   title: "Mobile Development",
          //   desc: `I throw myself down among the tall grass by the stream as I lie
          //       close to the earth.`,
          // },
          // {
          //   icon: "users",
          //   title: "Marketing & Reporting",
          //   desc: `I throw myself down among the tall grass by the stream as I lie
          //       close to the earth.`,
          // },
          // {
          //   icon: "monitor",
          //   title: "Mobile Development",
          //   desc: ` I throw myself down among the tall grass by the stream as I lie
          //       close to the earth.`,
          // },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
