<template>
  <v-row class="row--35" align="center">
    <v-col lg="5" md="5" cols="12">
      <div class="thumbnail">
        <slot name="thum-img"></slot>
      </div>
    </v-col>
    <v-col lg="7" md="7" cols="12">
      <div class="about-inner inner pt--100 pt_sm--40 pt_md--40">
        <div class="section-title">
          <h2 class="heading-title">My Essence</h2>
          <p class="description">
<!--            There are many variations of passages of Lorem Ipsum available, but-->
<!--            the majority have suffered-->
<!--            <a href="#">alteration</a> in some form, by injected humour, or-->
<!--            randomised words which dont look even slightly believable. If you-->
<!--            are going to use a passage of Lorem Ipsum,-->
            Driven computer science professional with nearly five years of
            experience in software development and consultation. With
            a strong educational background with Master's degrees, I
            have a proven track record of delivering high-quality
            software solutions to meet complex business requirements.
            Skilled in various programming languages and technologies, I am adept at designing, developing, and
            implementing software applications that drive operational
            efficiency and enhance customer experience.
<!--            I thrive in-->
<!--            dynamic and fast-paced environments where I can-->
<!--            continuously learn and grow, and am a collaborative team-->
<!--            player with excellent communication and interpersonal skills,-->
<!--            able to work effectively with cross-functional teams to-->
<!--            achieve project goals.-->
          </p>
        </div>
        <!-- End .section-title -->
        <div class="tab-wrapper mt--30">
          <v-row>
            <TabTwo />
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import TabTwo from "../../components/tabs/TabTwo";
  export default {
    components: { TabTwo },
    data() {
      return {};
    },
  };
</script>
